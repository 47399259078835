import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import GoogleTrends from "../../components/UI-Elements/GoogleTrend";
import Card from "../../components/UI-Elements/Card";
import Title from "@components/UI-Elements/Typography/Title";

const Dashboard = () => {
  return (
    <Layout contentWidth="w-full lg:px-16">
      <SEO keywords={['google trends', 'charts']} title="Google Trends" />
      <Title className="mb-5">Google Trends</Title>

      <div className="grid xl:grid-cols-2 gap-5 mt-5">
        <Card title="DAPP Plattformen">
          <p className="mb-3">Wie schlagen sich die Ethereum Konkurrenten?</p>
          <GoogleTrends time="today 12-m" keywords={["Ethereum", "Solana", "Cardano", "Avalanche", "Terra"]} />
        </Card>
        <Card title="Bitcoin vs. Ethereum">
          <GoogleTrends time="today 5-y" keywords={["Bitcoin", "Ethereum"]} />
        </Card>
        <Card title="Gibt es einen neuen Hype?">
          <p className="mb-3">Gibt es einen neuen Hype? Wie schlägt sich NFT im Vergleich zu Decentralized Finance (DeFi)?</p>
          <GoogleTrends time="today 12-m" keywords={["DeFi", "NFT", "Metaverse", "Bitcoin", "Web 3.0"]} />
        </Card>
        <Card title="MEME Coins">
          <GoogleTrends time="today 3-m" keywords={["Dogecoin", "Shiba Inu coin"]} />
        </Card>
        <Card title="Gaming und Metaverse">
          <GoogleTrends time="today 12-m" keywords={["Decentraland", "Sandbox", "Axie Infinity"]} />
        </Card>
      </div>
    </Layout>
  );
};

export default Dashboard;
